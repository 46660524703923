export const STATUS_TEAM = [
  { label: "status.active", value: "active", isChecked: false },
  {
    label: "status.inactive",
    value: "inactive",
    isChecked: false,
  },
];

export const STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};
