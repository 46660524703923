import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IResourceComponentsProps,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Create, TextField, useForm } from "@refinedev/antd";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  InputRef,
  Select,
  Space,
  message,
} from "antd";

import { IListApartments, IListApointments, IListUser } from "interfaces";
import { STATUS_OPTIONS, STATUS_OPTIONS_LIMIT } from "const";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatHour,
  handleFilterOption,
  handleSortOption,
  isReallyPhone,
  parsePhone,
  parsePhoneToVN,
  parsePhoneToVNWithoutSpace,
} from "helper";
import { CloseSquareOutlined, PlusOutlined } from "@ant-design/icons";

export const AppointmentsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const location = useLocation();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;

  const { formProps, saveButtonProps, onFinish, form } =
    useForm<IListApointments>({
      errorNotification: (data) => {
        const errorMessage: any = data?.response;
        const message: any = errorMessage?.data?.data?.errors[0].message;
        return {
          message: message,
          description: "Error",
          type: "error",
        };
      },
    });
  const [optionsSales, setOptionsSales] = useState<[]>([]);
  const [optionsApartments, setOptionsApartments] = useState<[]>([]);
  const [selectedItemsApartments, setSelectedItemsApartments] = useState<
    string[]
  >(location?.state?.selectedRowKeys || []);
  const [optionsCustomers, setOptionsCustomers] = useState<[]>([]);
  const [currentOptionCustomer, setCurrentOptionCustomer] = useState<any>([]);
  const [currentLengthNewCustomer, setCurrentLengthNewCustomer] =
    useState<number>(0);

  const [customerInformationItems, setCustomerInformationItems] = useState<any>(
    []
  );

  const [optionsSalesSupport, setOptionsSalesSupport] = useState<[]>([]);

  const [selectedItemsSalesSupport, setSelectedItemsSalesSupport] = useState<
    string[]
  >([]);
  const [selectedDateTime, setSelectedDateTime] = useState<any>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const inputRef = useRef<InputRef>(null);

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments?status=vacant,near_vacant&",
    errorNotification: false,
  });

  // get Sale data
  const { data: salesData } = useList<IListUser>({
    resource: "users?role_ids=4,5",
    errorNotification: false,
  });

  // get customers data
  const { data: customersData } = useList<IListUser>({
    resource: "customers",
    errorNotification: false,
  });

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (!name?.trim()) {
      message.error(translate("validations.name"));
      return;
    }
    if (!phone?.trim()) {
      // message.error(translate("validations.phone"));
      return;
    }
    if (!isReallyPhone(phone?.trim())) {
      message.error(translate("validations.notAPhoneNumber"));
      return;
    }
    e.preventDefault();
    setCustomerInformationItems([
      ...customerInformationItems,
      { name: name, phone: parsePhone(phone) },
    ]);
    setName("");
    setPhone("");

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onChangeDate = (value: DatePickerProps["value"]) => {
    setSelectedDateTime(value?.toISOString());
  };

  const handleOnFinish: any = (values: IListApartments) => {
    const currentCustomerInFo = customerInformationItems?.filter(
      (item: any) => item?.phone === values["customer_info"][0]
    );
    onFinish({
      ...values,
      time: selectedDateTime,
      customer_id:
        currentOptionCustomer?.length === 0 ? null : currentOptionCustomer,
      customer_info: currentCustomerInFo[0]
        ? {
            full_name: currentCustomerInFo[0]?.name,
            phone: currentCustomerInFo[0]?.phone,
          }
        : null,
      apartment_ids: selectedItemsApartments,
    });
  };

  useEffect(() => {
    if (apartmentsData) {
      const optionsApartmentsData: any = apartmentsData?.data;
      setOptionsApartments(
        optionsApartmentsData?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name + ` (${item?.building?.name})`,
          };
        })
      );
    }
  }, [apartmentsData]);

  useEffect(() => {
    if (customersData) {
      const optionsCustomersData: any = customersData?.data;
      setOptionsCustomers(
        optionsCustomersData?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.full_name,
            subLabel:
              parsePhoneToVN(item?.phone) +
              " " +
              parsePhoneToVNWithoutSpace(item?.phone) +
              " " +
              item?.phone,
          };
        })
      );
    }
  }, [customersData]);

  useEffect(() => {
    if (salesData) {
      const salesOptionsData: any = salesData?.data;
      setOptionsSales(
        salesOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
      setOptionsSalesSupport(
        salesOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
    }
  }, [salesData]);

  const filteredOptionsApartments = optionsApartments?.filter(
    (o: any) => !selectedItemsApartments?.includes(o)
  );

  const filteredOptionsSalesSupport = optionsSalesSupport?.filter(
    (o: any) => !selectedItemsSalesSupport?.includes(o)
  );

  const clearCustomer = () => setCurrentOptionCustomer(null);

  return (
    <Create
      title={translate(
        "pages.customerManagement.appointments.createNewAppointment"
      )}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          status: "pending",
          apartment_ids: location?.state?.selectedRowKeys,
        }}
        form={form}
      >
        <Form.Item
          name="apartment_ids"
          label={translate("pages.customerManagement.appointments.apartments")}
          rules={[
            {
              required: true,
              message: translate("validations.apartments"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManyApartments")}
            value={selectedItemsApartments}
            onChange={setSelectedItemsApartments}
            options={filteredOptionsApartments}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="customer_id"
          label={translate(
            "pages.customerManagement.appointments.customersAlreadyHave"
          )}
          rules={[
            () => ({
              validator(_, value) {
                if (
                  !value &&
                  currentOptionCustomer?.length === 0 &&
                  currentLengthNewCustomer === 0
                ) {
                  message.error(
                    translate("validations.mustChooseCustomerOrAddNew")
                  );
                  return Promise.reject(new Error());
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <span style={{ display: "flex" }}>
            <Select
              placeholder={translate("placeholder.selectACustomer")}
              options={optionsCustomers}
              onChange={(value) => setCurrentOptionCustomer(value)}
              value={currentOptionCustomer}
              showSearch
              disabled={currentLengthNewCustomer > 0 ? true : false}
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
            />
            {currentOptionCustomer > 0 ? (
              <Button onClick={clearCustomer}>
                <CloseSquareOutlined />
              </Button>
            ) : null}
          </span>
        </Form.Item>

        <Form.Item
          name="customer_info"
          label={translate(
            "pages.customerManagement.appointments.addMoreCustomers"
          )}
          rules={[
            () => ({
              validator() {
                if (
                  currentOptionCustomer?.length === 0 &&
                  currentLengthNewCustomer === 0
                ) {
                  return Promise.reject(new Error());
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            disabled={currentOptionCustomer > 0 ? true : false}
            onChange={(value, options) => {
              if (options?.length === 0 || options?.length === 1) {
                setCurrentLengthNewCustomer(value?.length);
              }
              if (value?.length > 1) {
                value.pop();
              }
            }}
            mode="multiple"
            placeholder={translate(
              "placeholder.selectOrAddCustomerInformations"
            )}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <TextField value="Name:" />
                  <Input
                    placeholder={translate("placeholder.pleaseEnterName")}
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                  />
                </Space>
                <Space style={{ padding: "0 8px 4px" }}>
                  <TextField value="Phone:" />
                  <Input
                    placeholder={translate("placeholder.pleaseEnterPhone")}
                    ref={inputRef}
                    value={phone}
                    onChange={onPhoneChange}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    {translate("buttons.addItem")}
                  </Button>
                </Space>
              </>
            )}
            options={customerInformationItems.map((item: any) => ({
              label: item?.name + ` (${item?.phone})`,
              value: item?.phone,
            }))}
          />
        </Form.Item>

        {isLoggedbyAdmin || isLoggedbyDataAdmin ? (
          <>
            <Form.Item
              name="sale_id"
              label={translate("pages.customerManagement.appointments.sale")}
              rules={[
                {
                  required: true,
                  message: translate("validations.sale"),
                },
              ]}
            >
              <Select
                placeholder={translate("placeholder.selectASale")}
                options={optionsSales}
                showSearch
                filterOption={(input, option: any) =>
                  handleFilterOption(input, option)
                }
                filterSort={(optionA, optionB) =>
                  handleSortOption(optionA, optionB)
                }
              />
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          name="supported_sale_ids"
          label={translate(
            "pages.customerManagement.appointments.supportSales"
          )}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManySales")}
            value={selectedItemsSalesSupport}
            onChange={setSelectedItemsSalesSupport}
            options={filteredOptionsSalesSupport}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>

        <Form.Item
          name="time"
          label={translate("pages.customerManagement.appointments.time")}
          rules={[
            {
              required: !selectedDateTime && true,
              message: translate("validations.date"),
            },
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={`${formatDateMonthYear} ${formatHour}`}
              placeholder={translate("placeholder.selectDateTime")}
              showTime={{ format: formatHour }}
              onChange={onChangeDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>

        <Form.Item
          name="notes"
          label={translate("pages.customerManagement.appointments.notes")}
        >
          <Input placeholder={translate("placeholder.pleaseEnterNotes")} />
        </Form.Item>

        <Form.Item
          name="status"
          label={translate("pages.customerManagement.appointments.status")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
            <Select
              disabled
              placeholder={translate("placeholder.selectStatus")}
              options={STATUS_OPTIONS?.map((item) => {
                return {
                  label: translate(item.label),
                  value: item.value,
                };
              })}
            />
          ) : (
            <Select
              disabled
              placeholder={translate("placeholder.selectStatus")}
              options={STATUS_OPTIONS_LIMIT?.map((item) => {
                return {
                  label: translate(item.label),
                  value: item.value,
                };
              })}
            />
          )}
        </Form.Item>
      </Form>
    </Create>
  );
};
